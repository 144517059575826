/* eslint-disable no-unused-vars */

/** @jsxImportSource @emotion/react */
import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled/macro'
import { jsx, css } from '@emotion/react'
import { NavLink } from 'react-router-dom';
import theme from '../../theme'




const navigation = css`
  display: flex;  
  position: fixed;
  top:0rem;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  
  margin-bottom: 3rem;
  z-index: +1;
  opacity: 1;
  background-color: #1C1E1F;

  flex-direction: row;
  color: red;
  justify-content: space-between;
  text-align: center;
  width:100vw;
  min-height:5rem;
  padding-bottom: 0;

  min-width: 100%;
  font-size: 1rem;

  @media (min-width: 300px) {
    font-size: 1rem;
  }
  @media (min-width: 400px) {
    font-size: 1.3rem;
  }
  @media (min-width: 500px) {
    font-size: 1.3rem;
  }

  @media (min-width: 800px) {
    font-size: 1.4rem;
  }
  a{
   
    padding: 1rem;
    margin-top: 0;
    height: 2rem;

    color: #ecf1ed;
    text-decoration: none;
    font-size: $font-size-small-mobile;
    @media (min-width: 500px) {
      font-size: $font-size-mobile;
    }

    @media (min-width: 800px) {
      font-size: $font-size-tablet;
    }

    @media (min-width: 1000px) {
      font-size: $font-size-desktop;
    }
  }
  a:hover {
    border-bottom: 2px solid #4fd1c5;
    text-decoration: none;
    padding-bottom: 2.3rem;
  }
  .is-active {
    border-bottom: 2px solid red;
  }

`;



const DesktopNavigation = () => {


  return (
    <nav className='flex justify-content-center text-white' css={navigation}>



      <NavLink className=''  to='/' >
        Home
        </NavLink >
      <NavLink className='' to='/about'>
        About
        </NavLink>

      <NavLink className='' to='/blog' >
        Blog
        </NavLink>
      <NavLink className='' to='/portfolio'>Portfolio</NavLink>
      <NavLink className='' to='/contact'>Contact</NavLink>
      <NavLink className='' to='/resume'>Resume</NavLink>

    </nav>


  )



}

export default DesktopNavigation;
