/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";

import { Navigation } from "./components/Navigation";
import Keybase from "./components/Keybase";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Portfolio } from "./pages/Portfolio";
import { Resume } from "./pages/Resume";
import { Home } from "./pages/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";
import DownloadMessage from "./pages/DownloadMessage";

import "./App.scss";

const style = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-color;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  #page-wrap {
    text-align: center;
    overflow: auto;
    top: 45%;
    position: relative;
  }
`;

const App = () => {
  return (
    <div css={style} className="wrapper">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/contact" component={Contact} />
          <Route
            path="/blog"
            render={() => (window.location = "https://blog.luisabellan.com")}
          />
          <Route
            path="/download-resume"
            component={DownloadMessage}
          />

          <Route path="/resume" component={Resume} />
          {/* <Route path="/keybase.txt" component={Keybase} /> */}
        </Switch>
      </Router>

      <AnimatedCursor />
    </div>
  );
};

export default App;
