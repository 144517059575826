/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Typical from 'react-typical'


const textColor = '#ecf1ed';

const title = css`

  font-family: 'Cabin', sans-serif;
  color: ${textColor};
  display: flex;
  justify-content: center;
  margin: 0 auto;
  white-space:nowrap;
  font-size: 1.4rem;

  @media (min-width: 330px) {
    font-size: 2rem;
  }

  @media (min-width: 800px) {
  }
`;

const photo = css`

  display:flex;
  justify-content:center;
  margin-top: 6rem;
  width: 12rem;

  @media (min-width: 330px) {
    width: 14rem;
  }
  @media (min-width: 500px) {
    width: 18rem;
  }
  @media (min-width: 800px) {
    width: 18rem;
  }
  @media (min-width: 975px) {
    width: 18rem;


  }
`;

const stack = css`

  margin:  0 auto;
  color: ${textColor};
  white-space: nowrap;
  width: 12rem;
  font-size: 0.9rem;

  @media (min-width: 400px) {
    width: 20rem;
  }

  @media (min-width: 800px) {
    font-size: 1.2rem;
  }
`;

const description = css`
  display:flex;
  justify-content:center;
  margin:0 auto;
  padding: 1rem;
  margin-top: -2rem;
  width: 100vw;
  color: ${textColor};


  @media (min-width: 330px) {
    width: 18rem;
  }
  @media (min-width: 500px) {
    width: 25rem;
  }
  @media (min-width: 800px) {
    width: 25rem;
  }
  @media (min-width: 975px) {
    width: 30rem;
  }
`;
const me =
  'https://res.cloudinary.com/luisabellan/image/upload/v1612921670/photo_jet28v.webp';
const meJpg =
  'https://res.cloudinary.com/luisabellan/image/upload/v1612921520/Portfolio/photo_ycuzfa.png';




const AboutDetails = () => {
  return (
      <><picture className='mx-auto'>
      <source srcSet={me} type='image/webp' />
      <img
        css={photo}
        className=' '
        src={meJpg}
        alt='me' />
    </picture><h3
      css={title}
      className='flex content-center self-center text-center mt-4 mb-0 mx-2 font-sans text-white whitespace-nowrap'
    >
        Full-Stack Developer
      </h3><h5
        css={stack}
        className='flex justify-center content-center self-center text-center my-0 mx-1 mt-2 font-sans text-white whitespace-nowrap'
      >
        PostgreSQL | Express | React
      </h5><h5
        css={stack}
        className='flex justify-center content-center self-center my-0 mx-auto mt-2 mb-5 font-sans text-white whitespace-nowrap'
      >
        {' '}
        Node | SQL | Python
      </h5><div className='flex flex-col px-0 mx-9 ' css={description}>
        <p className="mx-auto whitespace-nowrap text-white max-width-sm">
          I'm&nbsp;
          <Typical
            className='typicalWrapper'
            loop={Infinity}
            wrapper="b"
            steps={[
              "Luis Abellan",
              1000,
              "a Software Developer",
              1000,
              "from Madrid, Spain",
              1000,
              "passionate about Jamstack",
              1000,
              "passionate about ML",
              1000,
              "passionate about AI",
              1000
            ]}
            speed='5' />
        </p>
      </div></>
  );
};

export default AboutDetails;
