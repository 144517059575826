
/* eslint-disable no-unused-vars */

/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React from "react";
import TextFileReader from "../TextFileReader/TextFileReader";
import keybaseText from "./keybase.txt";



function Keybase() {



  return (


    <TextFileReader
      txt={keybaseText}
    />
  )

}

export default Keybase;