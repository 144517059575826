/* eslint-disable no-unused-vars */

/** @jsxImportSource @emotion/react */
import React from 'react'
import styled from '@emotion/styled/macro'
import { jsx, css } from '@emotion/react'
import { NavLink } from 'react-router-dom';
import DesktopNavigation from './DesktopNavigation';
import TouchNavigation from './TouchNavigation';
import useWindowWidth from '../../hooks/useWindowWidth';


const Navigation = () => {

  return (
    useWindowWidth() >= 600 ? <DesktopNavigation linkText="iEarn Careers" /> : <TouchNavigation linkText="iEarn Careers" />




  );
};

export default Navigation;
