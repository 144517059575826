/*
  Reads a text file and outputs its content.

  Example Usage:
  import myTxt from "./myTxt.txt";
  ...
  <TextFileReader
    txt={myTxt}
  />
 */

import React from "react";


const style = {

  color: 'white',
}


class TextFileReader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: ""
    };
  }

  componentDidMount() {
    this.readTextFile(this.props.txt);
  }

  readTextFile = myFile => {
    const file = new XMLHttpRequest();
    file.open("GET", myFile, false);
    file.onreadystatechange = () => {
      if (file.readyState === 4) {
        if (file.status === 200 || file.status === 0) {
          const allText = file.responseText;
          this.setState({
            text: allText
          });
        }
      }
    };
    file.send(null);
  };

  render() {
    return (
      <div>
        <pre style={style}>{this.state.text}</pre>
      </div>
    );
  }
}

export default TextFileReader;