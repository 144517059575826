export const data = [
  {
    id: 1,
    title: "Express Groomer",
    deployed: "https://main.d1l0i3i010ko4e.amplifyapp.com/",
    webp: "https://res.cloudinary.com/luisabellan/image/upload/v1612667025/Portfolio/express-groomer_yq9rfi.webp",
    png: "https://res.cloudinary.com/luisabellan/image/upload/v1612666859/Portfolio/express-groomer_iubvtu.png",
    using:
      "React, Node, Express, PostgreSQL, Jest, Ant Design, Google Places APIs",
    responsibilities: "FULL-STACK",
    more_details: {
      description1:
        "Express Groomer is a web application that connects pet owners with nearby groomers",
      description3: "Registration forms, Map component and react-geocode",
      description2: "Database Design, Created Schema, Migrations and Seeding ",
    },
    github1: "https://github.com/luisabellan/LabsPT12-Express_Groomer-TeamA-FE",
    github2: "https://github.com/luisabellan/LabsPT12-Express_Groomer-TeamA-BE",
  },

  {
    id: 2,
    title: "Nasa Photo of the Day",
    deployed: "https://nfod.netlify.app/",
    webp: "https://res.cloudinary.com/luisabellan/image/upload/v1612664182/Portfolio/nasa1_fjfgq1.webp",
    png: "https://res.cloudinary.com/luisabellan/image/upload/v1612664183/Portfolio/nasa1_ds23uk.png",
    using: "React, SCSS, Axios, Moment.js, css-in-jss, React-DatePicker",
    responsibilities: "FRONT-END",
    more_details: {
      description1:
        "Consumes a public NASA API to show a different photo every day along with a description.",
      description2: "",
      description3: "",
    },
    github1: "https://github.com/luisabellan/nasa-photo-of-the-day-project",
  },
  {
    id: 3,
    title: "Github Profiles",
    deployed: "https://gitprofiles.netlify.app/",
    webp: "https://res.cloudinary.com/luisabellan/image/upload/v1612664182/Portfolio/github-card_iou6tp.webp",
    png: "https://res.cloudinary.com/luisabellan/image/upload/v1612664181/Portfolio/github-card_i0nkxi.png",
    using: "HTML5, CSS, JS, API, Axios",
    responsibilities: "FRONT-END",
    more_details: {
      url: "",
      description1:
        "Accesses the GitHub API and builds a social card based on the data we get back.",
      description2:
        "Created a component based on the data got from the GitHub API.",
      description3: "",
    },
    github1: "https://github.com/luisabellan/github-profiles",
  },
  {
    id: 4,
    title: "Rick and Morty Fan Page",
    deployed: "https://rickandmorty-squarerobin.netlify.com",
    webp: "https://res.cloudinary.com/luisabellan/image/upload/v1612664181/Portfolio/rickandmorty_zietzx.webp",
    png: "https://res.cloudinary.com/luisabellan/image/upload/v1612664182/Portfolio/rickandmorty_mwmtom.png",
    using: "React, JS, SASS, Single Page Applications, React Hook Forms",
    responsibilities: "FRONT-END",
    more_details: {
      url: "",
      description1: "It consumes a 3rd party API service based on the TV show.",
      description2: "",
      description3: "",
    },
    github1: "https://github.com/luisabellan/rickandmorty",
  },
  {
    id: 5,
    title: "Car sales shopping cart",
    deployed: "https://adoring-mcnulty-88f409.netlify.app",
    webp: "https://res.cloudinary.com/luisabellan/image/upload/v1612664182/Portfolio/car-sales_wr33fg.webp",
    png: "https://res.cloudinary.com/luisabellan/image/upload/v1612664181/Portfolio/car-sales_htd8th.png",
    using: "React, JS, SASS, Single Page Applications, React, Redux, Bulma",
    responsibilities: "FRONT-END",
    more_details: {
      description1: "Implemented Redux as the state management system.",
      description2: "Styled the app with Bulma.",
      description3: "",
    },
    github1: "https://github.com/luisabellan/car-sales-project",
  },
  {
    id: 6,
    title: "React Shopping Cart",
    deployed: "https://react-shopping-cart-steel.now.sh",
    webp: "https://res.cloudinary.com/luisabellan/image/upload/v1612664182/Portfolio/react-shopping-cart_b4rxel.webp",
    png: "https://res.cloudinary.com/luisabellan/image/upload/v1612664182/Portfolio/react-shopping-cart_eu6bqe.png",
    using: "React, React Router, ContextAPI, SASS, testing-library",
    responsibilities: "FRONT-END",
    more_details: {
      description1: "Refactored e-commerce store to use Context API.",
      description2:
        "Added the functionality of adding new items to the shopping cart",
      description3: "",
    },
    github1: "https://github.com/luisabellan/react-shopping-cart",
  },
  {
    id: 7,
    title: "Portfolio",
    deployed: "https://luisabellan.com",
    webp: "https://res.cloudinary.com/luisabellan/image/upload/v1634939651/portfolio_h81ozr.webp",
    png: "https://res.cloudinary.com/luisabellan/image/upload/v1634939613/portfolio_sgqhwi.png",
    responsibilities: "FRONT-END",
    using: "React, Tailwind, Emotion, Twin, type.js",
    more_details: {
      description1:
        "This is my portfolio. It links to my blog and resume ",
      description2: "",
      description3: "",
    },
    github1: "https://github.com/luisabellan/portfolio",
  },

  {
    id: 8,
    title: "Refugee Stories",
    deployed: "https://refugees-stories-984.netlify.app/",
    webp: "https://res.cloudinary.com/luisabellan/image/upload/v1632411519/Portfolio/refugees-stories_j1g1vc.webp",
    png: "https://res.cloudinary.com/luisabellan/image/upload/v1632411377/Portfolio/refugees-stories_iqal5m.png",
    using: "React, MaterialUI, ReactStrap, Styled Components",
    responsibilities: "FRONT-END",
    more_details: {
      description1:
        "Site where refugees can share their stories.",
      description2: "Authentication using jsonwebtokens.",
      description3: "",
    },
    github1: "https://github.com/luisabellan/refugee-stories-front-end",
  },
  {
    id: 9,
    title: "Better Professor",
    deployed: "https://refugees-stories-984.netlify.app/",
    webp: "https://res.cloudinary.com/luisabellan/image/upload/v1634939229/better-professor_lurpcg.webp",
    png: "https://res.cloudinary.com/luisabellan/image/upload/v1634938864/better-professor_bhgish.png",
    using: "Node, Express, PostgreSQL, jsonwebtokens, Jest",
    responsibilities: "BACK-END",
    more_details: {
      description1:
        "Companion app that helps teachers manage their classes.",
      description2: "Authentication using jsonwebtokens.",
      description3: "",
    },
    github1: "https://github.com/luisabellan/BetterProfessor-BE",
  },
  {
    id: 10,
    title: "Todo App",
    deployed: "https://todo-app-245.netlify.app",
    webp: "https://res.cloudinary.com/luisabellan/image/upload/v1612664181/Portfolio/todo-app_jlzabw.webp",
    png: "https://res.cloudinary.com/luisabellan/image/upload/v1612664181/Portfolio/todo-app_xvwi8n.png",
    using: "React, Emotion, Tailwind, i18n, Styled Components",
    responsibilities: "FRONT-END",
    more_details: {
      description1:
        "Supports five languages: English, Spanish, German, French, Arabic",
      description2: "",
      description3: "",
    },
    github1: "https://github.com/luisabellan/todo-app",
  },

];
