/* eslint-disable no-unused-vars */

/** @jsxImportSource @emotion/react */
import React, { useState, useRef } from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled/macro'
import { jsx, css } from '@emotion/react'
import { NavLink } from 'react-router-dom';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Burger, Menu } from '../../components';
import FocusLock from 'react-focus-lock';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../global';
import { theme } from '../../theme';

const style = css`

    display: flex;
    position: fixed;
    top: 0;
    

`;

const TouchNavigation = () => {
    const [open, setOpen] = useState(false)
    const node = useRef();
    const menuId = "main-menu";

    useOnClickOutside(node, () => setOpen(false));

    const closeOnClick = (e) => {
        e.preventDefault();
        setOpen(!open)
        console.log(node.current)
    }


    return (
        <ThemeProvider theme={theme}>
        <>
        <GlobalStyles />

        <div  ref={node}>
             <FocusLock disabled={!open}>
             <Burger open={open}  setOpen={setOpen} aria-controls={menuId} />

            <Menu
                open={open} setOpen={setOpen} id={menuId}
            />
            </FocusLock>
        {open ? null : (<Menu />)}


        </div>
        </>
        </ThemeProvider>
    )


}


export default TouchNavigation