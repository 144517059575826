export const theme = {
  primaryDark: '#0D0C1D',
  primaryLight: '#EFFFFA',
  primaryHover: '#343078',
  // colors 2
  textColor: '#ecf1ed',
  socialIcons: '#74f34d',
  orangeAccent: '#f3b94d',
  stars: '#74f34d',
  boldText: '#74f34d',
  backgroundColor: '#1c1e1f',
  buttonBgColor: '#74f34d',

  // font sizes

  fontSizeSmallMobile: '0.875rem',
  fontSizeMobile: '1.15rem',
  fontSizeTablet: '1.15rem',
  fontSizeDesktop: '1.3rem',

  // breakpoints

  //mobile: '320px',
  desktop: '775px',
}