/* eslint-disable no-unused-vars */

import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'
import About from '../About/About';
import { Navigation } from '../../components/Navigation';



const Home = () => {
  
  return (
    <div id='home'>
      <About />

     
    </div>
  );
};
export default Home;
