
/* eslint-disable no-unused-vars */

/** @jsxImportSource @emotion/react */

import { jsx, css } from '@emotion/react'

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {theme} from '../../theme'

import { bool } from 'prop-types';
import styled from 'styled-components';


const Message = styled.div`
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 1 !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin: 0 auto;

  p {
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.orangeAccent};
    color: whitesmoke;
    text-decoration: none;
    transition: color 0.3s linear;
    @media (max-width: ${({ theme }) => theme.desktop}) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
  a {
    
    padding-bottom: 0.5em;
    padding-right: 0.5em;
    padding-left: 0.5em;
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    text-decoration: none;

    text-align: center;
    border: 1px solid whitesmoke;
    color: whitesmoke;
   
    &:hover {
      // background:${({ theme }) => theme.orangeAccent})
      background: orange
  
    }
  }
  span {
    font-size: 1em;
  }

 

`;

const button = css`
  display: flex;
  justify-content: center;
  border-radius: 5%;
  width: 5em;
  height: 2rem;
  self: center;

  span {
    padding-top: 0.3rem;
  }

`;

const DownloadMessage = () => {

  window.location = 'https://drive.google.com/uc?export=download&id=1aurSn3t1nAKkcTofSK76_zkG6ZnC89LL'

    return (
        <Message className="download-message">
            <p>Thanks for Downloading my resume</p>
            <Link css={button} to="/resume" ><span>Go back</span></Link>
        </Message>

    )
}

export default DownloadMessage;