import Button from "react-bootstrap/Button";

const myButton = (props) => {
  return (
    <Button
      className={props.buttonClassName}
      target={props.target}
      rel="noopener noreferrer"
      href={props.href}
      variant={props.variant}
    >
      <i
        css={{ color: "#4fd1c5" }}
        className="fa fa-github fa-lg aria-hidden='true'"
      ></i>
      <p className="text-white my-2 text-center mx-auto">{props.text}</p>
    </Button>
  );
};

export default myButton;
