/* eslint-disable no-unused-vars */

/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { jsx, css } from '@emotion/react'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Footer from '../../components/Footer/Footer';
import { theme } from '../../theme';
import { Navigation } from '../../components/Navigation';

const textColor = '#ecf1ed';

const style = css`

    color: ${textColor};
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

   /*  @media (min-width:theme.desktop){
      //width: 100vw;
      //height: 100vh;
    } */

    textarea {
      margin: 0 auto;
      padding: 0.5rem;
      width: 15rem;
      height: 20rem;
      overflow: auto;
        @media (min-width:400px){
        width: 20rem;
      }
      @media (min-width:500px){
        width: 25rem;
      }
    }
h2{
    display: flex;
align-self: center;
font-family: 'Cabin', sans-serif;
color: ${textColor};
font-size: 2rem;
padding-top: 3rem;

@media (min-width: 500px) {
  font-size: 2.5rem;
}

@media (min-width: 800px) {
  font-size: 3rem;
}
}



`
const form = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  line-height: 2rem;
  font-family: "Baloo Bhai", cursive;
  margin: 0 auto;
  padding: 0;

  @media (min-width: 500) {
  }

  input,
  textarea {
    background-color: grey;
    width: 15rem;
      @media (min-width:400px){
        width: 20rem;
      }
        @media (min-width: 500px) {
        width: 25rem;
      }
  }

  .name {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.2rem;



    label {
      display: flex;
      @media (min-width: 500px) {
        font-size: $font-size-tablet;
      }

      @media (min-width: 800px) {
        font-size: $font-size-desktop;
        align-self: flex-start;
      }
    }

    .name {
      border: 1px solid black;
      padding: 0.5rem;
      font-family: "Exo 2", sans-serif;
      font-size: 1.2rem;
      display: flex;


    }
  }

  .email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-self: center;
    margin-bottom: 1rem;




    label {
      align-self: flex-start;
      flex: 1;

      @media (min-width: 500px) {
        font-size: $font-size-tablet;
      }

      @media (min-width: 800px) {
        font-size: $font-size-desktop;
        align-self: flex-start;
      }
    }

    input.email {
      border: 1px solid black;
      padding: 0.5rem;
      display: flex;
      align-self: flex-end;
      flex: 1;
      font-family: "Exo 2", sans-serif;
      font-size: 1.2rem;

      @media (min-width: 500px) {
        width: 25rem;
        align-self: center;
      }
    }


    .message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-self: center;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0.5rem;
      color: whitesmoke;


      label {
      color: whitesmoke;

      @media (min-width: 500px) {
        align-self: flex-start;
        font-size: $font-size-tablet;
      }

      @media (min-width: 800px) {
        font-size: $font-size-desktop;
        align-self: flex-start;

      }
    }
  }



  .recaptcha {
    margin-top: 2rem;
  }

  .send-button-container {
    @include button(10rem);
  

    .send-button {
      display: flex;
      justify-content: center;
      justify-items: center;
      height: 3rem;
      padding-top: 0.75rem;
      color: whitesmoke;
    }
  }

`;

const h2 = css`

`;

const Contact = () => {
  const [name, setName] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      data.append(k, data[k]);
    });
    return formData;
  };

  const handleSubmit = (e) => {
    const formData = { 'form-name': 'contact', name, email, message };

    fetch('/', {
      method: 'POST',
      // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
      body: encode(formData),
    })
      .then(() => setStatus('Form Submission Successful!!'))
      .catch((error) => setStatus('Form Submission Failed!'));

    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      return setName(value);
    }
    if (name === 'email') {
      return setEmail(value);
    }
    if (name === 'message') {
      return setMessage(value);
    }
  };

  return (
      <section id='contact' css={style} className='pt-14'>
        <Navigation />
        <h2 className='flex flex-col mx-auto text-center'>Contact</h2>

        <form css={form} className='mx-auto h-122 ' onSubmit={handleSubmit} action='/thank-you/'>
          <div className='name'>
            {/*     <p className="hidden">
            <label>
              Don&apos;t fill this out if you&apos;re human:{" "}
              <input name="bot-field" />
            </label>
          </p> */}

            <input className='flex flex-col mx-auto text-center' type='hidden' name='form-name' value='contact' />
            <label className='' htmlFor='name'>Name </label>
            <input
              className='name'
              type='text'
              name='name'
              onChange={handleChange}
              value={name}
              required
            />
          </div>
          <div className='email'>
            <label htmlFor='email'>Email </label>
            <input
              className='email'
              name='email'
              type='email'
              value={email}
              onChange={handleChange}
              required
            />
          </div>
          <div className='flex flex-col '>
            <label htmlFor='message'>Message </label>
            <div className=''>
              <textarea
                type='text'
                className='textarea'
                name='message'
                id='message'
                value={message}
                onChange={handleChange}
              />
            </div>
          </div>
          <div data-netlify-recaptcha='true' className='recaptcha'></div>
          <Container className='flex f-col justify-center mt-4 mx-auto send-button-container'>
            <Button
              className='send-button'
              type='submit'
              variant='outline-dark'
            >
              <i className="fas fa-paper-plane aria-hidden='true'"></i>Send
            </Button>
          </Container>
        </form>
      <Footer />
      </section>
  );
};
export default Contact
