import { createGlobalStyle } from 'styled-components'

/* img {
    border-radius: 5px;
    height: auto;
    width: 10rem;

  } */
export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }

  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryLight};
    height: 100vh;
    text-rendering: optimizeLegibility;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  h1 {
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
  }
  
  div {
    text-align: center;
  }
  small {
    display: block;
  }
  a {
    color: ${({ theme }) => theme.primaryHover};
    text-decoration: none;
  }

  .cursor {
    width: 40px;
    height: 40px;
    border: 2px solid #fefefe;
    border-radius: 100%;
    position: fixed;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: all 150ms ease;
    transition-property: background-color, opacity, transform, mix-blend-mode;
    z-index: 9999;
    mix-blend-mode: difference;
}

.cursor--hidden {
    opacity: 0;
}

.cursor--link-hovered {
    transform: translate(-50%, -50%) scale(1.25);
    background-color: #fefefe;
}

.cursor--clicked {
    transform: translate(-50%, -50%) scale(0.9);
    background-color: #fefefe;
}

html,
body {
    cursor: none;
    background-color: #1C1E1F;
    color: #fefefe;
    font-family: "Space Mono", sans-serif;
}

html *,
body * {
    cursor: none;
}

h1 {
    margin-bottom: 0.7em;
    font-size: 2em;
    font-weight: 600;
    text-align: center;
}

p {
    width: 80%;
    max-width: 32em;
    margin: 0 auto 1em;
    line-height: 1.7;
    font-weight: 300;
}

a {
    text-decoration: underline;
    color: #fefefe;
}
`