/* eslint-disable no-unused-vars */

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Footer from '../../components/Footer/Footer'
import { Navigation } from '../../components/Navigation';
import { AboutDetails } from '../../components/AboutDetails';


const textColor = '#ecf1ed';


const style = css`
  display:flex;
  justify-content:center;
  margin:0 auto;
  overflow-x:hidden;
  overflow-y: hidden;
  width: 100%;
  min-height: 100vh;
  h2{
    color: ${textColor};
  }
  .typicalWrapper::after {
    // content: "|"; //there use to be a cursor here, eliminated it.
    content: "";
    animation: blink 1s infinite step-start;
  }

  @keyframes blink {
    50% { opacity: 0; }
  }
`;


const social = css`
  font-size: 3rem;
`;

const icon = css`
  color: rgb(79, 209, 197);
  font-size: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;

  @media (min-width: 300px){
    font-size: 2.3rem;
  }

`;

const descParagraphs = css`
  font-size: 0.6rem;
  color: ${textColor};


  @media (min-width: 330px) {
    font-size: 1rem;
  }
  @media (min-width: 500px) {
    font-size: 1.5rem;
  }
  @media (min-width: 800px) {
    font-size: 2rem;
  }
`;


const About = () => (
  <section
    id='about'
    css={style}
    className='flex flex-col justify-center'
  >
    <Navigation />
    <div className='flex flex-col'>
      <AboutDetails />
    </div>
    
    <div css={social} className='flex justify-center mx-auto mb-20'>
      <a
        className=' '
        target='_blank'
        rel='noopener noreferrer'
        href='https://twitter.com/luisabellandev'
      >
        <i
          className="fab fa-twitter social aria-hidden='true'"
          css={icon}
          alt='twitter'
        ></i>
      </a>
      <a
        className=''
        target='_blank'
        rel='noopener noreferrer'
        href='https://github.com/luisabellan/'
      >
        <i
          className="fab fa-github social aria-hidden='true' "
          alt='github'
          css={icon}
        ></i>
      </a>

      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.linkedin.com/in/luis-abellan-dev/'
        className=''
      >
        <i

          className="fab fa-linkedin social aria-hidden='true'"
          alt='linkedin'
          css={icon}
        ></i>
      </a>
    </div>
    <Footer />
  </section >
);
export default About;
