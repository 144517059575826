/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import _ from "lodash";
import { data } from "../../data";
import { Project } from "../../components/Project/";
import { Footer } from "../../components/Footer/";
import { Navigation } from "../../components/Navigation/";

const showProject = (project) => {
  return (
    <div key={project.id.toString()} className="project">
      <Project
        className="project"
        title={project.title}
        deployed={project.deployed}
        description1={project.more_details.description1}
        description2={project.more_details.description2}
        description3={project.more_details.description3}
        webp={project.webp}
        png={project.png}
        using={project.using}
        responsibilities={project.responsibilities}
        github1={project.github1}
      // github2={project.github2}
      />
    </div>
  );
};

const style = css`
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  width: 100wh;
`;
const Portfolio = () => {
  return (
    <section id="portfolio" css={style} className="mx-auto pt-10">
      <Navigation />
      <h2>Portfolio</h2>
      <div className="projects">{_.map(data, showProject)}</div>
      <Footer />
    </section>
  );
};
export default Portfolio;
