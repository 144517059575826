/* eslint-disable no-unused-vars */

/** @jsxImportSource @emotion/react */

import { jsx, css } from "@emotion/react";

import React, { useState } from "react";
//import { Document, Page } from "react-pdf";
import Pdf from "../../files/Abellan-Luis-Resume.pdf";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navigation/Navigation";
import { theme } from "../../theme";

import { Document, Page } from "../../../node_modules/react-pdf/dist/esm/entry.webpack";
import "../../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css";

const style = css`



  //  mixins

  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;





  .download-link {

    display: flex;
    flex-direction: column;
    color: white;


  }



  p {
    color: ${theme.textColor};
    display: flex;
    font-size: 1rem;
    margin: 0 auto;

  }

  Button{
    display: flex;

    background-color: ${theme.backgroundColor} ;

    PDFDownloadLink{

      padding-top: 30rem;
    }
  }
  Button:hover {
    text-decoration: none;
    background-color: ${theme.backgroundColor};

      a{
        text-decoration: none;
      }

  }

  body {
    margin: 0;
    background-color: ${theme.backgroundColor};
    font-family: Segoe UI, Tahoma, sans-serif;

  }

  .Example {
    input, button {
      font: inherit;
    }



    header {
      background-color: ${theme.backgroundColor};
      box-shadow: 0 0 8px rgba(0, 0, 0, .5);
      padding: 20px;
      color: white;

      h1 {
        font-size: inherit;
        margin: 0;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0;
      padding: 10px;


      &__load {
        margin-top: 1em;
        color: white;
      }

      &__document {
        margin: 1em 0;

        .react-pdf {
          &__Document {
            display: flex;
            flex-direction: column;
            align-items: center;

          }

          &__Page {
            max-width: 90%;
            box-shadow: 0 0 8px rgba(0, 0, 0, .5);
            margin: 2em;




            canvas {
              max-width: 100%;
              height: auto !important;
              padding-top: 10rem;


            }
          }

          &__message {
            padding: 20px;
            color: white;
          }
        }
      }
    }
  }

`;

const buttons = css`
  display: flex;
  justify-content: center;
  border-radius: 5%;
  width: 5em;
  height: 2rem;

  span {
    padding-top: 0.3rem;
  }
`;

const downloadResume = css`
  display: flex;
  justify-content: center;
  border: 2px solid ${theme.textColor};
  width:fit-content;
  padding: 0.5em;
  text-decoration: none;
  
  a {
    color: ${theme.textColor};
    
    &:hover {
      text-decoration:none;
    }
  }
  
  span{
    align-self: center;
    padding: 2em;
    padding-bottom: 2em;
  }



`;
const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};
const Resume = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onClickHandler(direction) {
    if (pageNumber + direction <= numPages && pageNumber + direction > 0) {
      setPageNumber(pageNumber + direction);
    }
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div css={style} className="resume flex flex-col pt-24 mt-2">
      <Navigation />
      <h2 className="flex flex-col mx-auto text-center text-white ">Resume</h2>

      <Document file={Pdf} onLoadSuccess={onDocumentLoadSuccess} options={options}>
        <Page className="" pageNumber={pageNumber} />
      </Document>
      <div
        css={downloadResume}
        className="flex f-col justify-center mt-10  mx-auto"
      >
        <Link className="no-underline" target="_blank" to="/download-resume">
          <span>Download</span>
        </Link>
      </div>

      <div className="pdf">
        <button
          css={buttons}
          className="button-back"
          onClick={() => onClickHandler(-1)}
          placeholder="Back"
          disabled={pageNumber === 1}
        >
          <span>Back</span>
        </button>
        <span className="flex justify-content-center mx-auto px-4 text-white">
          Page {pageNumber} of {numPages}
        </span>
        <button
          css={buttons}
          className="button-next"
          onClick={() => onClickHandler(1)}
          placeholder="Next"
          disabled={pageNumber === numPages}
        >
          <span>Next</span>
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default Resume;
