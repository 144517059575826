/** @jsxImportSource @emotion/react */
import React from "react";
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
// import styled from '@emotion/styled/macro'
// import { jsx, css } from '@emotion/react'
import { css } from "@emotion/react";

import Container from "react-bootstrap/Container";
import { Button } from "../Button";

import { motion } from "framer-motion";

const textColor = "#ecf1ed";
const style = css`
  margin: 0 auto;
  margin-bottom: 0rem;
  min-height: 20rem;
  height: 40rem;


`;
const button = css`
  position: relative;
  width: 12rem;
`;
const responsibilities = css`
  margin: 0 auto;
  min-height: 1rem;
  max-height: 12rem;

  span {
    color: $stars;
    white-space: nowrap;
    padding-top: 3rem;
  }

  p {
    font-size: 0.875rem;
    color: $text-color;
    font-family: "Exo 2", sans-serif;

    @media (min-width: 400px) {
      font-size: $font-size-mobile;
    }

    @media (min-width: 500px) {
      font-size: $font-size-tablet;
    }

    @media (min-width: 800px) {
    }

    @media (min-width: 1200px) {
    }
  }
`;

const description = css`
  flex: 1;
  height: 10rem;
  font-size: 0.875rem;
  margin-top: 4rem;

  p {
    color: $text-color;
    font-family: "Exo 2", sans-serif;
    line-height: 1.6rem;
    width: 15rem;

    @media (min-width: 400px) {
    }

    @media (min-width: 500px) {
    }

    @media (min-width: 800px) {
    }
  }
`;

const h3 = css`
  display: flex;
  justify-content: center;
  font-family: 'Cabin', sans-serif;
  color: ${textColor};
  margin: 0 auto;
  text-align: center;

  @media (min-width: 500px) {
    font-size: 8rem;
  }

  @media (min-width: 800px) {
    font-size: 8rem;
  }
`;
const bullets = css`
  font-family: "Cabin", sans-serif;
  font-size: 0.875rem;
  color: ${textColor};

  @media (min-width: 500px) {
  }

  @media (min-width: 800px) {
  }
`;

const builtUsing = css`

  margin: 0 auto;

  font-size: 0.875rem;
  span {
    color: $orange-accent;
    white-space: nowrap;

    @media (min-width: 400px) {
      font-size: $font-size-mobile;
    }

    @media (min-width: 500px) {
      font-size: $font-size-tablet;
    }

    @media (min-width: 800px) {
      font-size: $font-size-desktop;
    }

    @media (min-width: 1260px) {
    }
  }

  p {
    font-family: "Exo 2", sans-serif;
    line-height: 1.2rem;
    color: $text-color;
    font-size: $font-size-small-mobile;

    @media (min-width: 400px) {
      font-size: $font-size-mobile;
      line-height: 1.3rem;
    }

    @media (min-width: 500px) {
      font-size: $font-size-mobile;
    }

    @media (min-width: 800px) {
      font-size: $font-size-tablet;
    }
  }


`;

const Project = (props) => {
  return (
    <div tw="mt-4 mx-auto text-center" className=" project " css={style}>
      <h3 className="mx-auto text-center " css={h3}>
        {props.title}
      </h3>
      <motion.a
        whileHover={{
          scale: 1.1,
        }}
        onHoverStart={(e) => { }}
        onHoverEnd={(e) => { }}
        target="_blank"
        rel="noopener noreferrer"
        href={props.deployed}
      >
        <picture>
          <source srcSet={props.webp} type="image/webp" />
          <img src={props.png} className="" alt="today" />
        </picture>
      </motion.a>
      <div
        tw="flex text-center mx-auto w-24 mb-0"
        className=" "
        css={responsibilities}
      >
        <p tw="flex mx-auto text-center text-white" className="">
          {props.responsibilities}
        </p>
      </div>
      <div tw="flex mx-auto w-64 pt-1 text-white" className="" css={builtUsing}>
        <p className="flex justify-content-center mx-auto text-center">
          {props.using}
        </p>
      </div>

      <div css={description} tw="mx-auto mt-4" className="">
        {props.description1 ? (
          <>
            <div tw="mb-6">
              <span>
                <i

                  css={{ color: "#4fd1c5" }}
                  className="fas fa-star fa-xs aria-hidden='true'"
                ></i>
              </span>
            </div>
            <div>
              <p
                tw="flex flex-col items-center self-center mx-auto text-white"
                className=""
                css={bullets}
              >
                {" "}
                {props.description1}
              </p>
            </div>
          </>
        ) : null}
        {/*
          <li>
            {props.description2 ? (
              <>
                <span>
                  <i
                    css={{ color: "#4fd1c5", width: "2rem" }}
                    className="fas fa-star fa-xs aria-hidden='true'"
                  ></i>
                  <p
                    className="flex justify-content-center mx-auto text-white"
                    css={bullets}
                  >
                    {props.description2}
                  </p>
                </span>
              </>
            ) : null}
          </li>*/}
      </div>

      {
        props.github1 ? (
          <div tw="mx-auto" className="button" css={button}>
            <Container className="github-button-container">
              <Button
                buttonClassName="github-button"
                target="_blank"
                href={props.github1}
                variant="outline-dark"
                text="Github"
              ></Button>
            </Container>
          </div>
        ) : null
      }
    </div >
  );
};

export default Project;
