/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import React from 'react'
import tw from 'twin.macro'
import { css } from '@emotion/react'

const style = css`
  display: flex;
  margin: 0;
  padding-top: 3rem;
  width: 100vw;
  height: 3vh;
  background-color: #1C1E1F;

`

const fonts = css`
  font-size: 0.5rem;

  @media (min-width: 300px) {
    font-size: 0.6rem;
  }
  @media (min-width: 500px) {
    font-size: 0.8rem;
  }
  @media (min-width: 800px) {
    font-size: 0.8rem;
  }
  @media (min-width: 975px) {
    font-size: 1rem;
  }
`;

const Footer = () => (
  <footer css={style} className='flex f-col w-full '>
    {/* {`&copy; Date.getFullYear() Luis Abellan`} */}
    {/*         <p>&copy;Luis Abellan 2020</p>
     */}{' '}
    <p className='md:text-md mx-auto' css={fonts}>
      &copy; {new Date().getFullYear()} Luis Abellan
    </p>
    <noscript>
      <p>&copy;Luis Abellan 2020</p>
    </noscript>
  </footer>
);

export default Footer;
